<!-- eslint-disable max-len -->
<template>
  <svg
    viewBox="0 0 24 24">
    <path
      d="M11.985,0.158c-6.517,0-11.818,5.301-11.818,11.818c0,6.516,5.301,11.818,11.818,11.818
      s11.817-5.302,11.817-11.818C23.803,5.459,18.502,0.158,11.985,0.158z M11.985,22.794c-5.965,0-10.818-4.853-10.818-10.818
      S6.02,1.158,11.985,1.158s10.817,4.853,10.817,10.818C22.803,17.941,17.95,22.794,11.985,22.794z"
    />
    <path
      d="M11.521,17.719c-0.276,0-0.5,0.224-0.5,0.5v0.362c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-0.362
      C12.021,17.943,11.798,17.719,11.521,17.719z"
    />
    <path
      d="M11.604,4.964c-1.634,0-3.158,0.783-3.978,2.043C7.475,7.239,7.541,7.548,7.772,7.698
      c0.23,0.152,0.541,0.085,0.691-0.146c0.638-0.979,1.841-1.588,3.14-1.588c1.984,0,3.598,1.391,3.598,3.101
      c0,1.317-1.08,2.341-3.303,3.129c-0.525,0.187-0.877,0.681-0.877,1.229v2.196c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5v-2.196
      c0-0.127,0.085-0.242,0.212-0.287c2.671-0.947,3.969-2.279,3.969-4.072C16.202,6.803,14.14,4.964,11.604,4.964z"
    />
  </svg>
</template>
